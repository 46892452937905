import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from './store'
import router from './router'
const app = createApp(App)


// 引入全局样式表
import './project/projectDefault/styles/cssStyles.css'
import './project/projectDefault/styles/vueStyles.css'


// 引入阿里巴巴矢量图标
import './project/projectDefault/assets/icon-font/iconfont.css'

import { createHead } from '@unhead/vue'
const head = createHead()
app.use(head)



//防止刷新页面时动态路由丢失，
import {getUserThemeData} from './router/index'
await getUserThemeData()


app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}



import { createPinia } from "pinia";

const pinia = createPinia()
app.use(pinia)


app.use(store)
app.use(router)
app.mount('#app')








// app.vue写在script里面  main.js写在app挂载完之后
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}
